.insurance__new-button {
  width: 300px;
}

.insurance-wrapper {
  padding-top: 1rem;
}

.insurance-card__name {
  color: var(--text-primary);
  font-size: 18px;
  font-weight: 700;
}
