.users-wrapper {
  padding-top: 1rem;
}

.user-card__name {
  color: var(--text-primary);
  font-size: 18px;
  font-weight: 700;
}

.user-card__role {
  color: var(--text-secondary);
}
