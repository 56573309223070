@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Raleway-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/Raleway-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/Raleway-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/Raleway-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/OpenSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/OpenSans-ExtraBold.ttf') format('truetype');
}
