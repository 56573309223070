.dashboard-wrapper {
  padding-top: 1rem;
}

.dashboard-date {
  font-size: 14px;
  padding-right: 26px;
}

.dashboard__needs-renewal {
  font-size: 14px;
}

.dashboard__needs-renewal__view-all {
  text-decoration: underline;
  cursor: pointer;
}

.dashboard__needs-renewal__patient-row {
  cursor: pointer;
}

  .db-card__wrapper {
    border-radius: 7px;
    box-shadow: 3px 5px 6px 2px #000000;
    box-shadow: 3px 5px 6px 2px rgba(0,0,0,0.1);
    margin-top: -28px;
    margin-right: 15px;
  }

.db-card__header {
  background-color: var(--dashboard-card-header);
  color: var(--white);
  border-radius: 7px 7px 0 0;
  font-family: 'Raleway', open-sans;
  font-size: 20px;
  font-weight: 700;
  padding: 16px 20px;
  cursor: pointer;
}

.db-card__arrow {
  margin-left: 20px;
}

.db-card__body {
  border-radius: 0 0 7px 7px;
  font-size: 14px;
  padding: 16px 20px;
  background-color: var(--white);
}

.db-card__count {
  height: 46px;
  width: 46px;
  border-radius: 50%;
  font-size: 14px;
  background-color: var(--secondary-yellow);
  z-index: 1000;
  position: relative;
}

.db-card__patient-name {
  cursor: pointer;
}

.db-card__view-all {
  cursor: pointer;
  font-size: 12px;
  text-decoration: underline;
  color: var(--text-purple);
}
