html,
body,
#root {
  height: 100%;
}


body {
  color: var(--text-primary);
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  letter-spacing: 0.17px;
  color: var(--text-primary);
}

h1 {
  font-size: 26px;
}

h2 {
  font-size: 20px;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 16px;
}

h1, h2, h3, h4, h5 {
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
}

.field-label {
  font-weight: 700;
  color: var(--dark-gray);
  font-family: 'Open Sans', sans-serif;
  padding-bottom: 5px;
}

.field-label__disabled {
  color: var(--light-gray);
}

.radio-label,
.switch-label {
  font-weight: normal;
}

.page-header {
  background-color: var(--dark-gray);
  color: white;
}

.header-text {
  font-family: 'Raleway', sans-serif;
  color: var(--header-text);
}

.footer {
  height: 60px;
  max-height: 60px;
  background-color: var(--primary-background);
}

.container-main {
  min-height: 100%;
  padding-bottom: 90px;
  background-color: var(--primary-background);
}

.loading-container {
  background-color: var(--primary-background);
}

.container-message {
  background-color: var(--primary-background);
  padding-top: .5rem;
}

.container-header {
  padding: 1rem 0;
}

.container-fluid.container__wide {
  padding-left: 3.5em;
  padding-right: 3.5em;
}

.text-secondary {
  color: var(--text-secondary);
}

.text-interactive {
  color: var(--text-interactive);
}

.cursor-pointer {
  cursor: pointer;
}

.no-select {
  user-select: none;
}

.no-margin {
  margin: 0;
}

.page_title {
  height: 47px;
}

.action-menu__button {
  width: 1.5rem;
  height: 22px;
  display: flex;
  justify-content: center;
  align-content: center;
  cursor: pointer;
}

.custom-card__index {
  margin-top: 1rem;
  border-radius: 7px;
  box-shadow: 0 2px 9px 0 #000000;
  box-shadow: 0 2px 9px 0 rgba(0,0,0,0.23);
}

.custom-card__add-new .p-card-content {
  display: flex;
  align-items: center;
}

.custom-card__text {
  padding-left: 1rem;
}

.custom-card__no-padding .p-card-content {
  padding: 0;
}

.custom-card__active {
  height: 1.5rem;
  width: 110px;
  margin-right: 1rem;
  font-size: 10px;
  background-color: var(--active-label);
  border-radius: 4px;
  display: flex; /* establish flex container */
  flex-direction: column; /* make main axis vertical */
  justify-content: center; /* center items vertically, in this case */
  align-items: center; /* center items horizontally, in this case */
}

/***** global button styling *****/
.button-primary,
.button-cancel,
.button-secondary {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 7px;
  font-weight: 700;
  height: 47px;
  outline: none;
  padding: 0 44px;
  margin: 0 !important;
}

.button-load-more {
  width: 100%;
}

.button-primary {
  background-color: var(--primary-button);
  border: none;
  color: var(--white);
}

  .button-primary:hover {
    background-color: var(--hover-purple);
  }

  .button-primary:focus {
    border: 2px solid var(--primary-button-focus-border);
    padding: 0 42px;
  }

.button-cancel,
.button-secondary {
  background-color: var(--white);
  color: var(--text-purple);
  border: 2px solid var(--primary-button);
}

  .button-cancel:hover,
  .button-secondary:hover {
    background-color: var(--cancel-button-hover);
  }

  .button-cancel:focus,
  .button-secondary:focus,{
    border-color: var(--cancel-button-focus);
  }

.button--fixed-width {
  width: 140px;
}

.header-button {
  width: 244px;
}

/*** end global button styling ***/

@media (min-width: 1280px) {
  .container-fluid.container__wide {
    padding-left: 4.875em;
    padding-right: 4.875em;
  }
}

@media not print {
  .print-only {
    display: none !important;
  }
}

@media print {
  .header__main-nav,
  footer,
  span[type=button],
  input[type=button],
  input[type=image],
  input[type=reset],
  input[type=search],
  input[type=submit],
  button,
  .alert {
    display: none;
  }

  input[type=text],
  input[type=number],
  textarea {
    /* Needed because inputs are very often manually styled with high specificity. */
    background-color: transparent !important;
    border: none !important;
  }

  .button--outline,
  .button--success,
  .button--fail,
  .button--fixed-width,
  .form-control,
  .btn,
  .btn-primary,
  .print-hidden {
    /* needed to override d-* classes from bootstrap */
    display: none !important;
  }

  .container-fluid.container__wide.container-main {
    padding: 0 1rem 1rem 1rem;
  }
}
