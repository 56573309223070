.p-dialog {
  border-radius: 7px;
}

.p-dialog .p-dialog-header .p-dialog-title {
  font-family: 'Raleway', sans-serif;
  font-size: 24px;
  color: var(--dark-gray);
}

.p-dialog .p-dialog-header {
  padding: 40px 40px 20px 40px;
  border-radius: 7px 7px 0 0;
}

.p-dialog .p-dialog-content {
  padding: 20px 40px;
}

.p-dialog .p-dialog-footer {
  border-radius: 0 0 7px 7px;
  border-top: none;
}

.p-inputswitch .p-inputswitch-slider {
  border-radius: 30px;
}

.p-inputswitch .p-inputswitch-slider:before {
  border-radius: 50%;
}

/*.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:checked:hover {
  background: red;
}*/

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  background: var(--patient-active);
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: var(--patient-active);
}


.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover,
.p-radiobutton .p-radiobutton-box.p-highlight,
.p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover,
.p-checkbox .p-checkbox-box.p-highlight {
  background: var(--prime-radio);
  border-color: var(--prime-radio);
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  border-color: var(--prime-radio);
}

.p-radiobutton:not(.p-radiobutton-disabled) .p-radiobutton-box.p-focus {
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px var(--prime-radio), 0 1px 2px 0 rgb(0 0 0);
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px var(--prime-radio), 0 1px 2px 0 rgb(0 0 0);
  border-color: var(--prime-radio);
}

.p-inputtext:enabled:focus,
.p-dropdown:not(.p-disabled).p-focus {
  border-color: var(--active-purple) !important;
}

.p-inputtext,
.p-dropdown {
  background-color: var(--editable-field-background);
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  background-color: var(--cancel-button-hover);
}

.p-dropdown .p-dropdown-trigger {
  color: var(--secondary-yellow);
}
