.pharmacies__new-button {
  width: 300px;
}

.pharmacies-wrapper {
  padding-top: 1rem;
}

.pharmacies-card__name {
  color: var(--text-primary);
  font-size: 18px;
  font-weight: 700;
}

.pharmacies-card__phone {
  color: var(--text-secondary);
}
