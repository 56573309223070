.header__main-nav {
  box-sizing: border-box;
  /*z-index: 9999;*/
  height: 6.21rem;
  padding: 0;
}

.header__main-nav .navbar-logo {
  height: 2.18rem;
}

.header__main-nav .dropdown-menu {
  left: unset;
  right: 15%;
  margin-top: -1.5rem;
  z-index: 1025;
}

.header__main-nav .dropdown-item {
  color: var(--text-primary);
  padding: 0.75rem 1.5rem;
}

.header__main-nav .dropdown-item.current {
  color: var(--text-secondary);
  font-weight: bold;
}

.header__main-nav .dropdown-item:hover {
  color: var(--mid-gray);
  font-weight: bold;
  background-color: inherit;
  cursor: pointer;
}

.nav-search-item .p-autocomplete {
  padding: 0 .5rem;
}

.nav-search-icon {
  padding-left: .5rem;
}

.nav-search-item,
.nav-item {
  height: 6.2rem;
  padding: 0 1rem;
  border-left: 1px solid var(--light-gray);
}

.nav-item {
  font-family: "Raleway", sans-serif;
}

.navbar-light .navbar-nav .nav-link {
  color: var(--text-secondary);
}

.nav-link:hover {
  font-weight: bold;
}

.nav-link.active {
  color: var(--text-secondary);
  font-weight: 800;
  box-shadow: inset 0 -5px var(--secondary-yellow);
}

.navbar-toggler:focus {
  border: 1px solid var(--text-primary);
  outline: none;
}

.header__main-nav .dropdown {
  border-right: 1px solid var(--light-gray);
}

.nav-initials {
  font-family: 'Raleway', sans-serif;
  font-size: 14px;
  font-weight: 700;
  border-radius: 50%;
  border: 4px solid var(--secondary-yellow);
  background-color: var(--primary-background);
  width: 44px;
  height: 44px;
}

.nav-initials.dropdown-toggle::after {
    content: none;
  }

.header__main-nav .dropdown-menu div.dropdown-item {
  cursor: default;
  border-bottom: 1px solid var(--light-gray);
}

.header__main-nav .dropdown-menu div.dropdown-item:hover {
  color: var(--text-primary);
  font-weight: normal;
}

.dropdown-user {
  padding-left: .5rem;
}

.dropdown-user-role {
  font-size: 12px;
}
