.stages__step-wrapper {
  box-shadow: 0 2px 8px 0 var(--light-gray);
  margin-bottom: 8px; /* for some reason we need this to get the box shadow to appear on this element */
}

.stages__new-button {
  width: 220px;
  padding: 0;
}

.stages-wrapper {
  padding-top: .5rem;
}

.stages-link {
  font-family: "Raleway", open-sans;
  cursor: pointer;
  padding: 10px 20px;
}

.stages-link.active {
  font-weight: 700;
  box-shadow: inset 0 -6px var(--secondary-yellow);
}

  .stages-card__icon {
    height: 40px;
    width: 40px;
    margin-bottom: 10px;
  }

.stages-card__name {
  color: var(--text-primary);
  font-size: 18px;
  font-weight: 700;
}

.stage-step-card {
  background: var(--stage-step-background);
  border-radius: 7px;
  padding-left: 10px;
  margin-top: 14px;
  box-shadow: 0 2px 9px 0 #000000;
  box-shadow: 0 2px 9px 0 rgba(0,0,0,0.23);
}

.p-card.stage-step-card .p-card-body {
  padding: 21px 12px 18px 40px;
  background: var(--white);
  border-radius: 0 7px 7px 0;
}

.p-card.stage-step-card .p-card-content {
  padding: 0;
}

.stage-step-card__active {
  font-size: 10px;
  margin-top: -2px;
}

.stage-step-card__inactive-dot {
  height: 8px;
  width: 8px;
  background-color: var(--patient-inactive);
  border-radius: 50%;
}

.stage-step-card__stage-name {
  font-size: 14px;
  color: var(--text-primary);
}

.stage-step-card__step-name {
  font-family: "Raleway", open-sans;
  font-size: 18px;
  color: var(--text-primary);
  font-weight: bold;
}

.stage-step-card_additional-date {
  padding-left: 20px;
  font-family: Raleway;
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
}

.stage-step-card__patient-name {
  color: var(--text-purple);
  font-family: "Raleway", open-sans;
  font-size: 20px;
  font-weight: bold;
}

.stage-step-card__patient-dob,
.stage-step-card__days,
.stage-step-card__comments {
  color: var(--mid-gray);
  font-size: 12px;
  padding-left: 14px;
  padding-right: 14px;
}

.stage-step-card__comments {
  cursor: pointer;
}

.stage-step-card__patient-dob {
  padding-left: 20px;
}

.step-comment__wrapper {
  border-top: 1px solid var(--light-gray);
  padding-top: 1rem;
}

.step-comment__initials {
  background-color: var(--secondary-yellow);
  height: 30px;
  width: 30px;
  border-radius: 50%;
  font-size: 10px;
}

.step-comment__date {
  font-size: 14px;
  font-weight: 700;
}

.step-comment__comment {
  font-size: 14px;
}

.stage-dialog__wrapper {
  padding-bottom: 30px;
}

.stage-step {
  height: 27px;
  width: 27px;
  border-radius: 50%;
  background-color: var(--light-gray);
  color: var(--white);
}

.stage-step__active {
  background-color: var(--primary-button);
}

.stage-step__text {
  font-family: 'Raleway', sans-serif;
  margin-top: 1px;
}

.stage-step__text-active {
  font-weight: 700;
}
