.step__new-button {
  width: 300px;
}

.patient-wrapper {
  padding-top: 1rem;
}

.patient-header__separator {
  width: 8px;
  height: 16px;
}

.patient-card {
  background: var(--patient-background);
  border-radius: 7px;
  padding-top: 10px;
  box-shadow: 0 2px 9px 0 #000000;
  box-shadow: 0 2px 9px 0 rgba(0,0,0,0.23);
}

.p-card.patient-card .p-card-body {
  padding: 18px 12px 18px 30px;
  background: var(--white);
  border-radius: 0 0 7px 7px;
}

.p-card.patient-card .p-card-content {
  padding: 0;
}

.patient-path {
  border-radius: 4px;
  background-color: var(--primary-background);
  padding: 5px 10px;
}

.patient-path__text {
  opacity: unset;
  color: var(--text-primary);
  font-size: 10px;
}

.patient-card__header {
  padding: 10px 0;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
}

.patient-card__active {
  font-size: 10px;
  margin-top: -2px;
}

.patient-card__active-dot {
  height: 8px;
  width: 8px;
  background-color: var(--patient-active);
  border-radius: 50%;
}

.patient-card__row {
  padding: 10px 0;
  font-size: 14px;
}

.patient-card__name {
  color: var(--header-text);
  font-family: 'Raleway', sans-serif;
  font-size: 20px;
  font-weight: 700;
}

.patient-card__comment-link {
  font-size: 12px;
  font-weight: 700;
  color: var(--header-text);
  cursor: pointer;
  text-decoration: underline;
}

.patient-car__detail-label {
  font-size: 12px;
  color: var(--mid-gray);
}

.patient-step__wrapper {
  padding-bottom: 30px;
}

.patient-step {
  height: 27px;
  width: 27px;
  border-radius: 50%;
  background-color: var(--light-gray);
  color: var(--white);
}

.patient-step__active {
  background-color: var(--primary-button);
}

.patient-step__text {
  font-family: 'Raleway', sans-serif;
}

.patient-step__text-active {
  font-weight: 700;
}

.add-comment {
  height: 10px;
  width: 10px;
  cursor: pointer;
}

.add-comment__text {
  color: var(--header-text);
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
}

.specialty-phone {
  padding-top: 10px;
}

.stage-section__label {
  font-family: "Raleway", open-sans;
  font-size: 20px;
  font-weight: 700;
  color: var(--text-primary);
}

.stage-section__title {
  cursor: pointer;
}

.stage-section__arrow-down {
  height: 10px;
  width: 18px;
}

.state-section__rotate {
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.prior-auth-card {
  background-color: var(--prior-auth-card);
  color: var(--white);
  border-radius: 11px;
  padding: 14px 20px;
}

.prior-auth-card__label {
  font-size: 14px;
  opacity: .78;
}

.prior-auth-card__data {
  font-weight: 700;
}
