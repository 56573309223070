.alert {
  border-radius: 7px;
  margin-top: .5rem;
  margin-bottom: 0;
}

  .alert.alert__danger {
    background-color: #FAECEC;
    border: 1px solid #D43D2A;
    color: var(--text-error);
  }

  .alert.alert__success {
    background-color: #CFE7C8;
    border: 1px solid #068518;
    color: var(--text-success);
  }

  .alert.alert__warning {
    background-color: #fff7ca;
    border: none;
    color: #4e4a34;
  }

  /* this will work provided the alerts are the first element in the "section" */

  .alert.alert__design:first-of-type {
    margin-top: 1rem;
  }

.close-button {
  border: none !important;
  outline: none !important;
}
