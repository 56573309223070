/*
  If a color value is changed here it will also need changed in ColorConstants
*/
:root {
  --dashboard-card-header: #684488;
  --prior-auth-card: #684488;
  --patient-background: #684488;
  --stage-step-background: #D8AF61;
  --header-text: #684488;
  --primary-button: #684488;
  --primary-button-focus-border: #D8AF61;
  --cancel-button-hover: #DFD7E0;
  --cancel-button-focus: #D8AF61;
  --primary-background: #F1F3F7;
  --primary-purple: #694289;
  --secondary-yellow: #D9B05C;
  --hover-purple: #9469B6;
  --active-purple: #351650; /*active field border */
  --editable-field-background: #FAFAFA;
  --selected-purple: #532C73;
  --hover-yellow: #9469B6;
  --active-yellow: #351650;
  --selected-yellow: #532C73;
  --white: #fff;
  --light-gray: #B6BEC6;
  --mid-gray: #979797;
  --dark-gray: #404040;
  --darker-gray: #282828;
  --text-primary: #404040;
  --text-secondary: #404040;
  --text-purple: #684488;
  --text-interactive: #694289;
  --text-disabled: #B6BEC6;
  --text-error: #D43D2A;
  --text-success: #068518;
  --active-label: #C7E6CB;
  --prime-radio: #DFBD7C;
  --patient-active: #8ECC97;
  --patient-inactive: #D43D2A;
}
